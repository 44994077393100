import {BrowserTracing} from '@sentry/tracing'
import * as Sentry from '@sentry/vue'
import dayjs from 'dayjs'
import 'dayjs/locale/fr'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import {createPinia} from 'pinia'
import {SessionStorageKeys} from 'src/enums'
import {useStore} from 'src/store'
import {createApp} from 'vue'
import {createVfm} from 'vue-final-modal'
import 'vue-final-modal/style.css'
import App from './App.vue'
import './assets/main.css'
import router from './router'

dayjs.extend(localizedFormat)
dayjs.locale('fr')

const app = createApp(App)
app.use(createPinia())

const vfm = createVfm()
app.use(vfm)

Sentry.init({
  app,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracePropagationTargets: [],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  logErrors: true,
  enabled: import.meta.env.VITE_ENVIRONMENT === 'production',
})

app.use(router)

const store = useStore()

// Watch for changes in the store and save to sessionStorage
store.$subscribe((mutation, state) => {
  if (state.orderRaw) sessionStorage.setItem(SessionStorageKeys.savedStore, JSON.stringify(state))
})

app.mount('#app')
