import apiService from '../apiService'
import {CustomerActionRequest, OrderStatusEnum} from '../enums'
import type {
  CalculateOrderAmountRequestPayload,
  ExtendPeriodDatePayload,
  LineItem,
  LineItemPostPayload,
  LineItemValidateReturnPostPayload,
  Order,
  OrderAmount,
  OrderRaw,
  Tenant,
} from '../interfaces'
import {getExtendedDate} from '../utils/utils'

export class OrderManager {
  static recalculate(order: Order): Promise<OrderAmount> {
    const payload: CalculateOrderAmountRequestPayload = {
      lineItems: order.lineItems.map((li: LineItem): LineItemPostPayload => {
        return {
          id: li.id,
          customerActionRequest: li.lastLineItemAmount.customerRequest,
          newVariantId: li.exchangeLineItem
            ? li.exchangeLineItem.lineItemData.cmsVariantId
            : undefined,
        }
      }),
    }
    return apiService.calculateOrderAmount(order.id, payload)
  }

  static async extendLimitEndDate(
    order: Order,
    tenant: Tenant,
    periodType: 'trial' | 'dropoff',
  ): Promise<OrderRaw> {
    order.isLimitEndDateExtendable = false
    const payload: ExtendPeriodDatePayload = {}
    if (periodType === 'trial') {
      const newDate = getExtendedDate(order.trialLimitEndDate, tenant.trialExtraDaysAllowed)
      order.trialLimitEndDate = newDate
      payload.trialLimitEndDate = newDate
    } else {
      const newDate = getExtendedDate(
        order.dropoffLimitEndDate,
        tenant.dropoffExtraWorkingDaysAllowed,
      )
      order.dropoffLimitEndDate = newDate
      payload.dropoffLimitEndDate = newDate
    }
    return apiService.extendPeriodDate(order.id, payload)
  }

  static isOrderConfirmable(orderStatus: OrderStatusEnum) {
    if (!orderStatus) return false
    return ![
      OrderStatusEnum.IN_RETURN_SHIPPING,
      OrderStatusEnum.PENDING_RETURN_CONTROL,
      OrderStatusEnum.PENDING_DROPOFF,
      OrderStatusEnum.CLOSED,
    ].includes(orderStatus)
  }

  static orderHasSomethingToReturn(order: Order) {
    return !order.lineItems.every(
      (lineItem: LineItem) =>
        lineItem.lastLineItemAmount.customerRequest === CustomerActionRequest.KEEP,
    )
  }

  static orderHasSomethingToExchange(order: Order) {
    return order.lineItems.some((lineItem: LineItem) =>
      [CustomerActionRequest.EXCHANGE, CustomerActionRequest.INSTANT_EXCHANGE].includes(
        lineItem.lastLineItemAmount.customerRequest,
      ),
    )
  }

  static orderHasInstantExchange(order: Order) {
    return order.lineItems.some((lineItem: LineItem) =>
      [CustomerActionRequest.INSTANT_EXCHANGE].includes(
        lineItem.lastLineItemAmount.customerRequest,
      ),
    )
  }

  static serializeLineItemsForValidation(order: Order) {
    return order.lineItems.map((li: LineItem): LineItemValidateReturnPostPayload => {
      return {
        id: li.id,
        customerActionRequest: li.lastLineItemAmount.customerRequest,
        newVariantId: li.exchangeLineItem
          ? li.exchangeLineItem.lineItemData.cmsVariantId
          : undefined,
        returnReasons: li.returnReasons,
      }
    })
  }
}
