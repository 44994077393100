import dayjs from 'dayjs'
import {CustomerActionRequest} from 'src/enums'

export const toCent = (decimalString: string): number | null => {
  const decimalNumber = parseFloat(decimalString)

  if (isNaN(decimalNumber)) {
    return null // Retourne null si la conversion échoue
  }

  const cents = Math.floor(decimalNumber * 100)
  return cents
}

export const cents_to_currency = (cents: number, currencyCode: string) => {
  return new Intl.NumberFormat(window.navigator.language, {
    style: 'currency',
    currency: currencyCode,
    currencyDisplay: 'narrowSymbol',
  }).format(cents / 100)
}

export const isPast = (date: number): boolean => {
  return Date.now() > date * 1000
}

export const getExtendedDate = (date: number, extendDays: number): number => {
  const initialDate = dayjs(date)
  const newDate = initialDate.add(extendDays, 'day')
  return newDate.valueOf()
}

export const toDate = (value: number) => {
  if (value) {
    // Utilisez dayjs pour formater la date
    return dayjs(value * 1000).format('DD MMMM YYYY')
  }
}

export const uppercaseFirstLetter = (input: string): string =>
  input.charAt(0).toUpperCase() + input.slice(1)

export const allUppercase = (input: string): string => input.toUpperCase()

export const customerRequestLabel = (
  customerRequest: CustomerActionRequest,
  isVanilla: boolean,
  isOrderConfirmable: boolean,
  isNonReturnable: boolean,
  isShipped: boolean,
) => {
  const labels = {
    isNotShipped: 'Article non-expédié',
    nonReturnable: 'Non-éligible au retour',
    exchange: 'Échange',
    instantExchange: 'Échange',
    giftCard: "Bon d'achat",
    vanillaRefund: 'Remboursement',
    tbybRefund: 'Retour',
    keep: 'Gardé',
  }

  if (!isShipped) {
    return labels.isNotShipped
  }

  if (isNonReturnable) {
    return labels.nonReturnable
  }

  switch (customerRequest) {
    case CustomerActionRequest.EXCHANGE:
      return labels.exchange
    case CustomerActionRequest.INSTANT_EXCHANGE:
      return labels.instantExchange
    case CustomerActionRequest.GET_GIFT_CARD:
      return labels.giftCard
    case CustomerActionRequest.GET_REFUND:
      return isVanilla ? labels.vanillaRefund : labels.tbybRefund
    case CustomerActionRequest.KEEP:
      return isOrderConfirmable ? '' : labels.keep
    default:
      return ''
  }
}

export const hasSlotContent = (slotName: string | number, slots: any) => {
  return Boolean(!!slots[slotName] && slots[slotName]()[0].children.length > 0)
}
