import {storeToRefs} from 'pinia'
import ApiService from 'src/apiService'
import {SessionStorageKeys} from 'src/enums'
import type {LineItem} from 'src/interfaces'
import {OrderManager} from 'src/managers/order'
import {useStore} from 'src/store'
import {DevEnvManager} from 'src/utils/dev-env-manager'
import {createRouter, createWebHistory, type RouteLocationNormalized} from 'vue-router'

const forceFollowReturnRedirection = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: any,
) => {
  const {order} = storeToRefs(useStore())
  if (order.value && !OrderManager.isOrderConfirmable(order.value.currentStatus)) {
    next('/follow-return')
  } else {
    next()
  }
}

const forceHomeRedirection = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: any,
) => {
  const {order} = storeToRefs(useStore())
  if (order.value && OrderManager.isOrderConfirmable(order.value.currentStatus)) {
    next('/')
  } else {
    next()
  }
}

const forceOnlineReturnRedirection = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: any,
) => {
  const {order} = storeToRefs(useStore())

  if (!order.value?.returnPolicy?.isStoreReturnEnabled) next('/')
  else next()
}

export const routes = {
  home: {
    path: '/',
    pathName: 'home',
  },
  login: {
    path: '/login',
    pathName: 'login',
  },
  returnMethods: {
    path: '/return-methods',
    pathName: 'returnMethods',
  },
  onlineReturn: {
    path: '/',
    pathName: 'onlineReturn',
  },
  inStoreReturn: {
    path: '/in-store-return',
    pathName: 'inStoreReturn',
  },
  lineItem: {
    path: '/line-item/:id',
    pathName: 'lineItem',
  },
  lineItemReturnReason: {
    path: 'return-reason',
    pathName: 'lineItemReturnReason',
  },
  lineItemNestedReturnReasons: {
    path: 'return-reason/:parentReturnReasonId',
    pathName: 'lineItemNestedReturnReasons',
  },
  lineItemFeaturedReturnOptions: {
    path: 'line-item-featured-return-options',
    pathName: 'lineItemFeaturedReturnOptions',
  },
  lineItemOtherOptions: {
    path: 'other-options',
    pathName: 'lineItemOtherOptions',
  },
  lineItemFamilyExchange: {
    path: 'family-exchange',
    pathName: 'lineItemFamilyExchange',
  },
  lineItemVariantExchange: {
    path: 'variant-exchange',
    pathName: 'lineItemVariantExchange',
  },
  howToReturn: {
    path: '/how-to-return',
    pathName: 'howToReturn',
  },
  followReturn: {
    path: '/follow-return',
    pathName: 'followReturn',
  },
  setTenant: {
    path: '/:tenant',
    pathName: 'setTenant',
  },
}

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: routes.home.path,
      name: routes.home.pathName,
      component: () => import('../views/HomeView.vue'),
      beforeEnter: forceFollowReturnRedirection,
      children: [
        {
          path: routes.returnMethods.path,
          name: routes.returnMethods.pathName,
          component: () => import('../views/return-methods/ReturnMethodsView.vue'),
          beforeEnter: forceOnlineReturnRedirection,
        },
        {
          path: routes.onlineReturn.path,
          name: routes.onlineReturn.pathName,
          component: () => import('../views/return-methods/OnlineReturnView.vue'),
        },
        {
          path: routes.inStoreReturn.path,
          name: routes.inStoreReturn.pathName,
          component: () => import('../views/return-methods/InStoreReturnView.vue'),
          beforeEnter: forceOnlineReturnRedirection,
        },
      ],
    },
    {
      path: routes.login.path,
      name: routes.login.pathName,
      component: () => import('../views/LoginView.vue'),
      props: route => ({
        tenantName: route.query.tenant,
        email: route.query.email,
        id: route.query.id,
      }),
    },
    {
      path: routes.lineItem.path,
      name: routes.lineItem.pathName,
      component: () => import('../views/line-item/LineItemView.vue'),
      beforeEnter: forceFollowReturnRedirection,
      children: [
        {
          path: routes.lineItemReturnReason.path,
          name: routes.lineItemReturnReason.pathName,
          component: () => import('../views/line-item/ReturnReasonView.vue'),
        },
        {
          path: routes.lineItemNestedReturnReasons.path,
          name: routes.lineItemNestedReturnReasons.pathName,
          component: () => import('../views/line-item/NestedReturnReasonsView.vue'),
        },
        {
          path: routes.lineItemFeaturedReturnOptions.path,
          name: routes.lineItemFeaturedReturnOptions.pathName,
          component: () => import('../views/line-item/FeaturedReturnOptionsView.vue'),
        },
        {
          path: routes.lineItemOtherOptions.path,
          name: routes.lineItemOtherOptions.pathName,
          component: () => import('../views/line-item/OtherReturnOptionsView.vue'),
        },
        {
          path: routes.lineItemFamilyExchange.path,
          name: routes.lineItemFamilyExchange.pathName,
          component: () => import('../views/line-item/FamilyExchangeView.vue'),
        },
        {
          path: routes.lineItemVariantExchange.path,
          name: routes.lineItemVariantExchange.pathName,
          component: () => import('../views/line-item/VariantExchangeView.vue'),
        },
      ],
    },
    {
      path: routes.followReturn.path,
      name: routes.followReturn.pathName,
      beforeEnter: forceHomeRedirection,
      component: () => import('../views/FollowReturnView.vue'),
    },
    {
      path: routes.howToReturn.path,
      name: routes.howToReturn.pathName,
      component: () => import('../views/HowToReturnView.vue'),
    },
    {
      path: routes.setTenant.path,
      name: routes.setTenant.pathName,
      redirect: to => {
        const {order_id, email_address} = to.query
        const {tenant} = to.params
        return `/login?id=${order_id || ''}&email=${email_address || ''}&tenant=${tenant || ''}`
      },
    },
    {
      path: '/:pathMatch(.*)*',
      redirect: '/',
    },
  ],
})

const publicPages = [routes.login.path]

router.beforeEach((to, from) => {
  if (to.name === routes.login.pathName) {
    if (import.meta.env.VITE_ENVIRONMENT !== 'production') {
      DevEnvManager.cleanStorage()
    } else {
      sessionStorage.clear()
    }
  }

  const {isLoggedIn} = storeToRefs(useStore())
  // redirect to login page if not logged in and trying to access a restricted page
  const authRequired = !publicPages.includes(to.path)
  if (authRequired && !isLoggedIn.value) {
    return {
      name: routes.login.pathName,
    }
  }
})

router.beforeEach(async (to, from) => {
  // On refresh or first SPA load
  if (from.name === undefined) {
    // Initial calls
    const {orderRaw, tenant, elynSettings, howToReturn, isAppLoading} = storeToRefs(useStore())
    const sessionStorageOrderId = sessionStorage.getItem(SessionStorageKeys.orderId)
    const tenantName = sessionStorage.getItem(SessionStorageKeys.tenantName)
    if (!sessionStorageOrderId || !tenantName) return
    if (!tenantName) return
    const tenantRetrieved = await ApiService.getTenantByName(tenantName)
    if (!tenantRetrieved) return
    tenant.value = tenantRetrieved
    const [orderRetrieved, HowToReturnRetrieved, settings] = await Promise.all([
      ApiService.getOrder(sessionStorageOrderId),
      ApiService.getHowToReturn(+sessionStorageOrderId),
      ApiService.getElynSettings(),
    ])
    orderRaw.value = orderRetrieved
    howToReturn.value = HowToReturnRetrieved
    elynSettings.value = settings

    // Restore user actions from sessionStorage
    const jsonSavedStore = sessionStorage.getItem(SessionStorageKeys.savedStore)
    if (jsonSavedStore) {
      const savedStore = JSON.parse(jsonSavedStore)
      if (savedStore.orderRaw?.lineItems) {
        orderRaw.value.lineItems.forEach((fetchedLineItem: LineItem, index) => {
          const savedLineItem = savedStore.orderRaw.lineItems.find(
            (lineItem: LineItem) => lineItem.id === fetchedLineItem.id,
          )
          if (savedLineItem) {
            orderRaw.value!.lineItems[index].lastLineItemAmount.customerRequest =
              savedLineItem.lastLineItemAmount.customerRequest
            orderRaw.value!.lineItems[index].returnReasons = savedLineItem.returnReasons
            orderRaw.value!.lineItems[index].exchangeLineItem = savedLineItem.exchangeLineItem
          }
        })
      }
    }
    setTimeout(() => {
      isAppLoading.value = false
    }, 300)
  }
})

// TODO Méthode redirection ancienne url

export default router
