export enum OrderStatusEnum {
  PENDING_AUTHORIZATION = 'pending_authorization',
  INITIAL_PAYMENT_EXPIRED = 'initial_payment_expired',
  PENDING_DELIVERY_TRACKING_INFORMATION = 'pending_delivery_tracking_information',
  IN_DELIVERY_SHIPPING = 'in_delivery_shipping',
  IN_TRIAL = 'in_trial',
  PENDING_DROPOFF = 'pending_dropoff',
  IN_RETURN_SHIPPING = 'in_return_shipping',
  PENDING_RETURN_CONTROL = 'pending_return_control',
  CANCELED_BY_ADMIN = 'canceled_by_admin',
  CLOSED = 'closed',
}

export enum OrderType {
  TRY_BEFORE_YOU_BUY = 'try_before_you_buy',
  VANILLA = 'vanilla',
  OTHER = 'other',
}

export enum CustomerActionRequest {
  GET_REFUND = 'get_refund',
  GET_GIFT_CARD = 'get_giftcard',
  EXCHANGE = 'exchange',
  INSTANT_EXCHANGE = 'instant_exchange',
  KEEP = 'keep',
}

export enum ReturnLabelGeneratorEnum {
  DIRECT_COLISSIMO = 'direct_colissimo',
  MARGUERITE = 'marguerite',
  MY_FLYING_BOX = 'flying_box',
  SYMPL = 'sympl',
  MONDIAL_RELAY = 'mondial_relay',
  UPS = 'ups',
  CROSSLOG = 'crosslog',
  INSTRUCTIONS_ONLY = 'instructions_only',
}

export enum SessionStorageKeys {
  orderId = 'orderId',
  tenantName = 'tenantName',
  accessToken = 'accessToken',
  savedStore = 'savedStore',
  devStore = 'devStore',
}

export enum OptionButton {
  EXCHANGE = 'EXCHANGE',
  FAMILY_EXCHANGE = 'FAMILY_EXCHANGE',
  GIFT_CARD = 'GIFT_CARD',
  REFUND = 'REFUND',
}

export enum ConfirmationModalStep {
  RETURN_DETAILS,
  EXCHANGE_TYPE,
  PAYMENT_FORM,
  RETURN_INFO,
}

export enum ExchangeTypeEnum {
  CLASSIC = 'classic',
  INSTANT = 'instant',
}

export enum PspEnum {
  STRIPE = 'stripe',
  ADYEN = 'adyen',
}

export enum InteractivityEnum {
  INTERACTIVE = 'interactive',
  READONLY = 'readonly',
  DISABLED = 'disabled',
}

export enum ModalsEnum {
  PDF_ERROR = 'pdf_error',
  MODIFY_RETURN = 'modify_return',
  ORDER_CONFIRMATION = 'order_confirmation',
}
