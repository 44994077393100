import {defineStore} from 'pinia'
import {CustomerActionRequest, OptionButton, SessionStorageKeys} from 'src/enums'
import {computed, ref, type ComputedRef} from 'vue'
import type {
  ElynSettings,
  HowToReturn,
  Order,
  OrderAmount,
  OrderRaw,
  SimilarProducts,
  Tenant,
} from './interfaces'

export const useStore = defineStore('store', () => {
  const isLoggedIn = ref<boolean>(sessionStorage.getItem(SessionStorageKeys.accessToken) !== null)
  const isAppLoading = ref<boolean>(true)
  const orderRaw = ref<OrderRaw | null>(null)
  const order: ComputedRef<Order | null> = computed(() => {
    if (!orderRaw.value) return null
    const isKeepAll = orderRaw.value.lineItems.every(
      item => item.lastLineItemAmount.customerRequest === CustomerActionRequest.KEEP,
    )

    const isOnlyIX =
      orderRaw.value.returnPolicy.isInstantExchangeReturnInterface &&
      !orderRaw.value.returnPolicy.isClassicExchangeReturnInterface

    const isOnlyClassic =
      !orderRaw.value.returnPolicy.isInstantExchangeReturnInterface &&
      orderRaw.value.returnPolicy.isClassicExchangeReturnInterface

    const lastStatus = orderRaw.value?.orderStatuses[orderRaw.value.orderStatuses.length - 1]

    if (lastStatus) {
      return {
        ...orderRaw.value,
        currentStatus: lastStatus.status,
        isKeepAll,
        isOnlyIX,
        isOnlyClassic,
      }
    }
    return null
  })
  const tenant = ref<Tenant | null>(null)
  const howToReturn = ref<HowToReturn | null>(null)
  const similarProducts = ref<SimilarProducts | null>(null)
  const orderAmount = ref<OrderAmount | null>(null)
  const elynSettings = ref<ElynSettings | null>(null)
  const featuredReturnOptions = computed(() => {
    if (!order.value) return []
    const options = []
    if (
      (order.value.returnPolicy.isFamilyExchangeReturnInterface ||
        order.value.returnPolicy.hasDifferentPriceExchange) &&
      !order.value.initialOrderId
    ) {
      options.push(OptionButton.FAMILY_EXCHANGE)
    }
    if (order.value.returnPolicy.isExchangeReturnInterface && !order.value.initialOrderId)
      options.push(OptionButton.EXCHANGE)
    return options
  })
  const otherReturnOptions = computed(() => {
    if (!order.value) return []
    const options = []
    if (order.value.returnPolicy.isGiftCardEnabled && !order.value.initialOrderId)
      options.push(OptionButton.GIFT_CARD)
    options.push(OptionButton.REFUND)
    return options
  })

  return {
    isAppLoading,
    isLoggedIn,
    orderRaw,
    order,
    howToReturn,
    tenant,
    orderAmount,
    similarProducts,
    elynSettings,
    featuredReturnOptions,
    otherReturnOptions,
  }
})
